const AppHeader = () => import(/* webpackChunkName: "error" */ '@/components/app-header/index.vue');
const Error404 = () => import(/* webpackChunkName: "error" */ '@/features/error/404/index.vue');
const Error500 = () => import(/* webpackChunkName: "error" */ '@/features/error/500/index.vue');

export default [{
  components: {
    header: AppHeader,
    default: Error404,
  },
  name: 'error-404',
  path: '/error-404',
}, {
  components: {
    header: AppHeader,
    default: Error500,
  },
  name: 'error-500',
  path: '/error-500',
}];
