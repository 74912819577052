import { createI18n } from 'vue-i18n';
import { LocaleI18n } from 'mph-helper';

export default createI18n({
  locale: localStorage.getItem('locale') || LocaleI18n.en,
  messages: {
    [LocaleI18n.en]: {},
    [LocaleI18n.fr]: {},
  },
});
