const AppHeader = () => import(/* webpackChunkName: "company-files" */ '@/components/app-header/index.vue');
const AppFooter = () => import(/* webpackChunkName: "company-files" */ '@/components/app-footer/index.vue');
const CompanyFiles = () => import(/* webpackChunkName: "company-files" */ '@/features/company-files/index.vue');
const Search = () => import(/* webpackChunkName: "company-files" */ '@/features/company-files/search/index.vue');
const CompanyFile = () => import(/* webpackChunkName: "company-files" */ '@/features/company-files/company-file/index.vue');

export default {
  components: {
    header: AppHeader,
    default: CompanyFiles,
    footer: AppFooter,
  },
  name: 'company-files',
  path: '/company-files',
  redirect: { name: 'company-files.search' },
  children: [{
    component: Search,
    name: 'company-files.search',
    path: 'search',
  }, {
    component: CompanyFile,
    name: 'company-files.company-file',
    path: 'company-file/:id?',
  }],
};
