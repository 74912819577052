import { createStore, Module } from 'vuex';
import {
  LanguagesStore,
  TLanguagesState,
  SecurityStore,
  TSecurityState,
} from 'mph-helper';
import i18n from '@/i18n';
import { TRootState } from '@/store/type';
import { LanguagesService } from '@/common/services/languages.service';
import { SecurityService } from '@/common/services/security.service';

export default createStore({
  actions: {
    getLanguages({ dispatch }) {
      return dispatch(`${LanguagesStore.name}/getLanguages`, {
        action: LanguagesService.getLanguages,
        i18n,
      });
    },
    getSecurity({ dispatch }) {
      return dispatch(`${SecurityStore.name}/getSecurity`, SecurityService.getSecurity);
    },
    securityLogout({ dispatch }) {
      return dispatch(`${SecurityStore.name}/securityLogout`, SecurityService.securityLogout);
    },
    async initApp() {
      // await dispatch('getLanguages');
      // await dispatch('getSecurity');
    },
  },
  modules: {
    [LanguagesStore.name]: LanguagesStore.module as Module<TLanguagesState, TRootState>,
    [SecurityStore.name]: SecurityStore.module as Module<TSecurityState, TRootState>,
  },
});
