/* eslint no-param-reassign: "error" */
import { InternalAxiosRequestConfig, AxiosHeaders, AxiosError, AxiosResponse } from 'axios';
import { LoaderStore } from 'mph-helper';
import i18n from '@/i18n';
import Router from '@/router';
import { http, baseURL, THttpRequestConfig } from '@/http';

type TConfigHeaders = AxiosHeaders & {
  'x-selected-language': string;
}

export const useHttpInterceptors = () => {
  const interceptorRequestSuccess = (config: InternalAxiosRequestConfig) => {
    if ((config as THttpRequestConfig).globalLoader) {
      LoaderStore.commit('startLoading');
    }
    (config.headers as TConfigHeaders)['x-selected-language'] = i18n.global.locale;
    return config;
  };
  const interceptorRequestError = (error: AxiosError) => {
    if ((error.config as THttpRequestConfig)?.globalLoader) {
      LoaderStore.commit('endLoading');
    }
    return Promise.reject(error);
  };
  const interceptorResponseSuccess = (response: AxiosResponse) => {
    if ((response.config as THttpRequestConfig).globalLoader) {
      LoaderStore.commit('endLoading');
    }
    return response;
  };
  const interceptorResponseError = async (error: AxiosError) => {
    if ((error.config as THttpRequestConfig)?.globalLoader) {
      LoaderStore.commit('endLoading');
    }
    if (error.response?.status === 401) {
      window.location.replace(`${baseURL.oauth2}/authorization/wso2`);
      return Promise.reject(error);
    }
    if (error.response?.status === 404 || error.response?.status === 500) {
      await Router.push(`/error-${error.response.status}`);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };

  http.interceptors.request.use(interceptorRequestSuccess, interceptorRequestError);
  http.interceptors.response.use(interceptorResponseSuccess, interceptorResponseError);
};
