<template>
  <el-config-provider size="large">
    <el-container
      v-loading.fullscreen.lock="isLoading"
      :class="$style.component"
      direction="vertical"
    >
      <router-view
        :class="$style.component__header"
        name="header"
      ></router-view>
      <router-view
        :class="$style.component__default"
        name="default"
      ></router-view>
      <router-view
        :class="$style.component__footer"
        name="footer"
      ></router-view>
    </el-container>
    <helper-app-dialogs ref="helperAppDialogsRef"></helper-app-dialogs>
  </el-config-provider>
</template>
<script lang="ts">
  import { defineComponent, ref, computed, provide, onMounted } from 'vue';
  import { LoaderStore, HelperAppDialogsInstance } from 'mph-helper';
  import i18n from '@/i18n';

  export default defineComponent({
    setup() {
      const helperAppDialogsRef = ref<HelperAppDialogsInstance>();
      const isLoading = computed(() => LoaderStore.getters.isLoading);
      const locale = localStorage.getItem('locale');
      if (locale) {
        i18n.global.locale = locale;
      }
      onMounted(() => {
        provide('dialog', helperAppDialogsRef.value);
      });
      return {
        helperAppDialogsRef,
        isLoading,
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    height: 100vh;
    overflow: auto;

    &__default {
      flex-grow: 1;
      overflow: auto;
    }
  }
</style>
