import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_helper_app_dialogs = _resolveComponent("helper-app-dialogs")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_config_provider, { size: "large" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_el_container, {
        class: _normalizeClass(_ctx.$style.component),
        direction: "vertical"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, {
            class: _normalizeClass(_ctx.$style.component__header),
            name: "header"
          }, null, 8, ["class"]),
          _createVNode(_component_router_view, {
            class: _normalizeClass(_ctx.$style.component__default),
            name: "default"
          }, null, 8, ["class"]),
          _createVNode(_component_router_view, {
            class: _normalizeClass(_ctx.$style.component__footer),
            name: "footer"
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["class"])), [
        [
          _directive_loading,
          _ctx.isLoading,
          void 0,
          {
            fullscreen: true,
            lock: true
          }
        ]
      ]),
      _createVNode(_component_helper_app_dialogs, { ref: "helperAppDialogsRef" }, null, 512)
    ]),
    _: 1
  }))
}