import qs from 'qs';
import { createRouter, createWebHistory, LocationQuery, RouteRecordRaw } from 'vue-router';
import CompanyFiles from '@/features/company-files';
import Error from '@/features/error';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'company-files' },
    children: [
      CompanyFiles,
      ...Error,
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  parseQuery(query) {
    return qs.parse(query) as LocationQuery;
  },
  stringifyQuery(query) {
    return qs.stringify({ ...query });
  },
});

export default router;
